import React from "react";
import PropTypes from "prop-types";
import Badge from "app/pages/.shared/Badge";
import PopoverInfo from "app/pages/.shared/Popover/PopoverInfo";
import { messagePropType } from "app/utils/propTypes";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./InsuranceRefundableCancellableBanner.scss";
import { FormattedMessage } from "react-intl";

export const InsuranceRefundableCancellableBanner = ({
	title,
	message,
	titleColor,
	background,
	tooltipMessage,
}) => {
	const showTootipButton = tooltipMessage.length > 0;

	return (
		<div className="insurance-refundable-cancellable-banner" style={{ background: background }}>
			<div className="insurance-refundable-cancellable-banner__content">
				<div className="insurance-refundable-cancellable-banner__header">
					<div className="insurance-refundable-cancellable-banner__title">
						<div className="icon icon--check-shield-white" />
						{title}
					</div>
					<div className="insurance-refundable-cancellable-banner__badge">
						<Badge style={{ color: titleColor }}>
							<FormattedMessage id="general.included" />
						</Badge>
					</div>
				</div>
				<div className="insurance-refundable-cancellable-banner__text">
					{message.length > 0 && (
						<Paragraphs paragraphs={message} enableHTMLFormatting={true} />
					)}
					{showTootipButton && (
						<PopoverInfo
							popOverButtonClassName="insurance-refundable-cancellable-banner__tooltip"
							popOverContent={
								<Paragraphs
									enableHTMLFormatting={true}
									paragraphs={tooltipMessage}
								/>
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

InsuranceRefundableCancellableBanner.propTypes = {
	title: messagePropType,
	message: PropTypes.array,
	tooltipMessage: PropTypes.array,
	background: PropTypes.string,
	titleColor: PropTypes.string,
};

InsuranceRefundableCancellableBanner.defaultProps = {
	message: [],
	tooltipMessage: [],
};

export default React.memo(InsuranceRefundableCancellableBanner);
